export const config = {
    DEFAULT_DATE_FORMAT: 'DD.MM.YYYY',
    DEFAULT_SHORT_DATE_FORMAT: 'DD.MM',
    DEFAULT_LONG_DATE_FORMAT: 'DD MMMM, YYYY',
    DEFAULT_FULL_DATE_FORMAT: 'DD.MM.YYYY LT',
    DEFAULT_SHORT_DATE_TIME_FORMAT: 'DD.MM.YYYY HH:mm',
    DEFAULT_FULL_DATE_TIME_FORMAT: 'DD MMMM YYYY, HH:mm',
    DEFAULT_TIME_FORMAT: 'HH:mm',
    MOMENT_FULL_DATE_FORMAT: 'MM/DD/YYYY HH:mm',
    MOMENT_DATE_FORMAT: 'MM/DD/YYYY',
    TABLE_FULL_DATA_TIME_FORMAT: 'ddd DD.MM.YYYY HH:mm',
    CARD_FULL_DATE_TIME_FORMAT: 'DD.MM | HH:mm',
    DATE_RANGE_PICKER_DATE_FORMAT: 'DD.MM.YYYY',
    DATE_PICKER_DATE_FORMAT: 'DD.MM.yyyy',
    DATE_PICKER_DATE_MASK: '__.__.____',
    DATE_PICKER_DATE_REGEX: /\d\d\.\d\d\.\d\d\d\d/,
    TIME_PICKER_FORMAT: 'HH:mm',
    TIME_PICKER_MASK: '__:__',
    SAVE_DEBOUNCE_DELAY: 1000,
};

export const reactAppName = {
    audit: 'audit',
    protocol: 'protocol',
    maintenance: 'maintenance',
    machine_maintenance: 'm-maintenance',
    progress: 'progress',
    workbook: 'workbook',
    process: 'process',
    maintenance_standard: 'maintenance-standard',
};

export const reactAppCustomer = {
    default: 'default',
    vwpwk: 'vw-pwk',
    dw: 'dw',
    bud: 'bud',
    gtcb: 'gtcb',
    std: 'std',
    todo: 'todo',
};

export const reactAppOrganization = {
    siemensBUD: 'SiemensBUD',
};

export const allowedFileTypes = {
    images: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
    documents: ['.doc', '.docx', '.xls', '.xlsx', '.pdf'],
    attachments: ['image/*', '.jpg', '.jpeg', '.png', '.gif', '.doc', '.docx', '.xls', '.xlsx', '.pdf'],
    videos: ['video/*', '.mp4', '.avi', '.mov', '.wmv', '.flv', '.3gp', '.mkv', '.webm'],
};

export const webcamModes = {
    picture: ['picture'],
    media: ['video-audio', 'picture'],
};
