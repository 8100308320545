import update from '../../../../../redux/update';

import { rigthBarActions } from './rigthBar.actions';

import { operationAtomicEnum } from '../../../../../constants/OperationAtomicEnum';
import { inputElements } from '../../../template-builder/constants/inputElements';

import { mapModelToTemplate, mapSimpleSectionResponseToViewModel } from '../utils/rigthBar.utils';
import createMongoObjectId from '../../../../../utils/createMongoObjID';
import { isArrayWithItems } from '../../../../../utils/arrayHelper';

const rigthBarInitialState = {
    protocolTemplate: {
        sectionModels: [],
    },
    showWarning: false,
    changesSaved: true,
    unsigningModal: {
        isOpen: false,
        sectionId: null,
    },
    signingModal: {
        isOpen: false,
        sectionId: null,
    },
    confirmationModal: {
        isOpen: false,
    },
    updateHandler: [],
    activeFile: null,
    activeFileFolder: null,
};

export const rigthBarReducer = (state = rigthBarInitialState, { type, payload }) => {
    switch (type) {
        case rigthBarActions.SET_PROTOCOL_TEMPLATE: {
            return {
                ...state,
                protocolTemplate: mapModelToTemplate(payload),
            };
        }
        case rigthBarActions.SET_UNSIGNING_MODAL_STATE: {
            return {
                ...state,
                unsigningModal: payload,
            };
        }
        case rigthBarActions.SET_SECTIONS_MODEL_PROTOCOL_TEMPLATE: {
            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.protocolSection?.map(section => {
                        const model = payload.find(model => model._id === section._id);

                        if (model) {
                            return mapSimpleSectionResponseToViewModel(model);
                        }

                        return section;
                    }),
                },
            };
        }
        case rigthBarActions.ADD_SECTION_MODEL_PROTOCOL_TEMPLATE: {
            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels?.map(section => (section._id === payload._id ? payload : section)),
                },
            };
        }
        case rigthBarActions.SET_IS_SHOW_WARNINGS: {
            return {
                ...state,
                showWarning: payload,
            };
        }
        case rigthBarActions.ADD_FINDING: {
            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels.map(section => {
                        if (section._id !== payload.sectionId) {
                            return section;
                        }

                        return {
                            ...section,
                            resolvedTickets: [...section.resolvedTickets, payload.ticket],
                        };
                    }),
                },
            };
        }
        case rigthBarActions.REMOVE_FINDING: {
            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels.map(section => {
                        if (section._id !== payload.sectionId) {
                            return section;
                        }

                        return {
                            ...section,
                            resolvedTickets: [...section.resolvedTickets.filter(ticket => ticket._id !== payload.id)],
                        };
                    }),
                },
            };
        }
        case rigthBarActions.UPDATE_FINDING: {
            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels.map(section => {
                        if (section._id !== payload.sectionId) {
                            return section;
                        }

                        return {
                            ...section,
                            resolvedTickets: section.resolvedTickets.map(ticket =>
                                ticket._id === payload.ticket._id ? payload.ticket : ticket
                            ),
                        };
                    }),
                },
            };
        }
        case rigthBarActions.UNSIGN_INSPECTION_REPORT: {
            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    metadata: {
                        ...state.protocolTemplate?.metadata,
                        signedBy: [],
                    },
                },
            };
        }
        case rigthBarActions.SIGN_INSPECTION_REPORT: {
            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    metadata: {
                        ...state.protocolTemplate?.metadata,
                        signedBy: [payload],
                    },
                },
            };
        }
        case rigthBarActions.SET_SIGNING_MODAL_STATE: {
            return {
                ...state,
                signingModal: payload,
            };
        }
        case rigthBarActions.CHANGE_SIGN_INSPECTION_REPORT_SECTION: {
            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels.map(section => {
                        if (section._id !== payload.sectionId) {
                            return section;
                        }

                        return {
                            ...section,
                            signedBy: [payload.model],
                        };
                    }),
                },
            };
        }
        case rigthBarActions.SET_ATTACHMENT: {
            const { activeFile, activeFileFolder } = payload;

            return {
                ...state,
                activeFile,
                activeFileFolder,
            };
        }
        case rigthBarActions.CHANGE_SIGN_INPUT_ELEMENT: {
            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels.map(section => {
                        if (section._id !== payload.sectionId) {
                            return section;
                        }

                        return {
                            ...section,
                            documentationCharacteristic: section.documentationCharacteristic.map(documentationCharacteristic => {
                                if (documentationCharacteristic._id !== payload.documentationCharacteristicId) {
                                    return documentationCharacteristic;
                                }

                                return {
                                    ...documentationCharacteristic,
                                    inputElement: documentationCharacteristic.inputElement.map(inputElement => {
                                        if (inputElement._id !== payload.inputElementId) {
                                            return inputElement;
                                        }

                                        return {
                                            ...inputElement,
                                            signedBy: payload.model,
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        }
        case rigthBarActions.CHANGE_ISVALID: {
            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels.map(section => {
                        if (section._id !== payload.sectionId) {
                            return section;
                        }

                        return {
                            ...section,
                            documentationCharacteristic: section.documentationCharacteristic.map(documentationCharacteristic => {
                                if (documentationCharacteristic._id !== payload.documentationCharacteristicId) {
                                    return documentationCharacteristic;
                                }

                                return {
                                    ...documentationCharacteristic,
                                    inputElement: documentationCharacteristic.inputElement.map(inputElement => {
                                        if (inputElement._id !== payload.inputElementId) {
                                            return inputElement;
                                        }

                                        return {
                                            ...inputElement,
                                            isValid: payload.value,
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
            };
        }
        case rigthBarActions.CHANGE_INPUT: {
            const { sectionId, documentationCharacteristicId, activeStep, inputElementId, value } = payload;

            const updateHandler = handleAtomicInputElementChange(
                state,
                activeStep,
                value,
                sectionId,
                documentationCharacteristicId,
                inputElementId
            );
            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels.map(section => {
                        if (section._id !== payload.sectionId) {
                            return section;
                        }

                        return {
                            ...section,
                            documentationCharacteristic: section.documentationCharacteristic.map(documentationCharacteristic => {
                                if (documentationCharacteristic._id !== payload.documentationCharacteristicId) {
                                    return documentationCharacteristic;
                                }

                                return {
                                    ...documentationCharacteristic,
                                    inputElement: documentationCharacteristic.inputElement.map(inputElement => {
                                        if (inputElement._id !== payload.inputElementId) {
                                            return inputElement;
                                        }

                                        return {
                                            ...inputElement,
                                            value,
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
                updateHandler,
            };
        }
        case rigthBarActions.CHANGE_FORMATTED_INPUT: {
            const { sectionId, documentationCharacteristicId, activeStep, inputElementId, value, formattedValue } = payload;

            const updateHandler = handleAtomicFormattedInputElementChange(
                state,
                activeStep,
                value,
                formattedValue,
                sectionId,
                documentationCharacteristicId,
                inputElementId
            );

            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels.map(section => {
                        if (section._id !== payload.sectionId) {
                            return section;
                        }

                        return {
                            ...section,
                            documentationCharacteristic: section.documentationCharacteristic.map(documentationCharacteristic => {
                                if (documentationCharacteristic._id !== payload.documentationCharacteristicId) {
                                    return documentationCharacteristic;
                                }

                                return {
                                    ...documentationCharacteristic,
                                    inputElement: documentationCharacteristic.inputElement.map(inputElement => {
                                        if (inputElement._id !== payload.inputElementId) {
                                            return inputElement;
                                        }

                                        return {
                                            ...inputElement,
                                            value,
                                            formattedValue,
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
                updateHandler,
            };
        }
        case rigthBarActions.CHANGE_RADIO_INPUT: {
            const { sectionId, documentationCharacteristicId, activeStep, InputElementsId, inputElementId, value } = payload;

            const updateHandler = handleAtomicRadioInputElementChange(
                state,
                activeStep,
                value === 'true' ? true : false,
                sectionId,
                documentationCharacteristicId,
                inputElementId,
                InputElementsId,
                'checked'
            );

            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels.map(section => {
                        if (section._id !== payload.sectionId) {
                            return section;
                        }

                        return {
                            ...section,
                            documentationCharacteristic: section.documentationCharacteristic.map(documentationCharacteristic => {
                                if (documentationCharacteristic._id !== payload.documentationCharacteristicId) {
                                    return documentationCharacteristic;
                                }

                                return {
                                    ...documentationCharacteristic,
                                    inputElement: documentationCharacteristic.inputElement.map(inputElement => {
                                        if (inputElement._id !== payload.inputElementId) {
                                            return {
                                                ...inputElement,
                                                checked: 'false',
                                            };
                                        }

                                        return {
                                            ...inputElement,
                                            checked: value,
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
                updateHandler,
            };
        }
        case rigthBarActions.CHANGE_FILE_INPUT: {
            const { sectionId, documentationCharacteristicId, activeStep, inputElementId, value, operation } = payload;

            const sectionIndex = state.protocolTemplate.sectionModels.findIndex(section => section._id === sectionId);
            const characteristicIndex = state.protocolTemplate.sectionModels[sectionIndex].documentationCharacteristic.findIndex(
                characteristic => characteristic._id === documentationCharacteristicId
            );
            const inputElementIndex = state.protocolTemplate.sectionModels[sectionIndex].documentationCharacteristic[
                characteristicIndex
            ].inputElement.findIndex(element => element._id === inputElementId);

            const inputElement =
                state.protocolTemplate.sectionModels[sectionIndex].documentationCharacteristic[characteristicIndex].inputElement[
                    inputElementIndex
                ];

            const mediaInput =
                operation === 'add'
                    ? [
                          ...inputElement.mediaInput,
                          {
                              mediaName: value,
                          },
                      ]
                    : inputElement.mediaInput.filter(media => media._id !== value);

            const updateHandler = handleAtomicInputElementChange(
                state,
                activeStep,
                mediaInput,
                sectionId,
                documentationCharacteristicId,
                inputElementId,
                'mediaInput'
            );

            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels.map(section => {
                        if (section._id !== payload.sectionId) {
                            return section;
                        }

                        return {
                            ...section,
                            documentationCharacteristic: section.documentationCharacteristic.map(documentationCharacteristic => {
                                if (documentationCharacteristic._id !== payload.documentationCharacteristicId) {
                                    return documentationCharacteristic;
                                }

                                return {
                                    ...documentationCharacteristic,
                                    inputElement: documentationCharacteristic.inputElement.map(inputElement => {
                                        if (inputElement._id !== payload.inputElementId) {
                                            return inputElement;
                                        }

                                        return {
                                            ...inputElement,
                                            mediaInput,
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
                updateHandler,
            };
        }
        case rigthBarActions.CLEAR_UPDATE_HANDLER: {
            return {
                ...state,
                updateHandler: [],
            };
        }
        case rigthBarActions.SET_INITIAL_STATE_RIGTH_BAR: {
            return rigthBarInitialState;
        }
        case rigthBarActions.AUTOFILL_COLUMN_SERIAL_NUMBER: {
            const { serialNumbers, columnNumber, sectionId } = payload;
            const activeStep = state.protocolTemplate._id;
            let newSection = state.protocolTemplate.sectionModels.find(section => section._id === sectionId);
            let newUpdateHandler = [];

            newSection = {
                ...newSection,
                documentationCharacteristic: newSection.documentationCharacteristic.map(
                    (documentationCharacteristic, documentationCharacteristicIndex) => {
                        if (documentationCharacteristic.column !== columnNumber) {
                            return documentationCharacteristic;
                        }

                        return {
                            ...documentationCharacteristic,
                            inputElement: documentationCharacteristic.inputElement.map(inputElement => {
                                if (
                                    inputElement.type !== inputElements.textInput ||
                                    inputElement.value === serialNumbers[documentationCharacteristic.row - 1]?.value
                                ) {
                                    return inputElement;
                                }

                                newUpdateHandler = handleAtomicInputElementChange(
                                    {
                                        updateHandler: newUpdateHandler,
                                    },
                                    activeStep,
                                    serialNumbers[documentationCharacteristic.row - 1]?.value,
                                    newSection._id,
                                    documentationCharacteristic._id,
                                    inputElement._id
                                );

                                return {
                                    ...inputElement,
                                    value: serialNumbers[documentationCharacteristic.row - 1]?.value,
                                };
                            }),
                        };
                    }
                ),
            };

            const countOfTheRows = newSection.rows;

            if (serialNumbers.length > countOfTheRows) {
                const lastRowCharacteristics = newSection.documentationCharacteristic.filter(
                    characteristic => characteristic.row === countOfTheRows
                );

                let newCharacteristics = [...newSection.documentationCharacteristic];

                for (let i = 0; i < serialNumbers.length - countOfTheRows; i++) {
                    lastRowCharacteristics.forEach(characteristic => {
                        newCharacteristics.push({
                            ...characteristic,
                            _id: createMongoObjectId(),
                            row: characteristic.row + i + 1,
                            inputElement: characteristic.inputElement.map(input => {
                                return {
                                    ...input,
                                    _id: createMongoObjectId(),
                                    value: characteristic.column === columnNumber ? serialNumbers[countOfTheRows + i].value : input.value,
                                };
                            }),
                        });
                    });
                }

                newSection = {
                    ...newSection,
                    rows: serialNumbers.length,
                    documentationCharacteristic: newCharacteristics,
                };

                newUpdateHandler = handleAtomicSectionChange(
                    newUpdateHandler,
                    activeStep,
                    sectionId,
                    newCharacteristics,
                    'documentationCharacteristic'
                );
            }

            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels.map(section => {
                        if (section._id !== sectionId) {
                            return section;
                        }

                        return newSection;
                    }),
                },
                updateHandler: isArrayWithItems(newUpdateHandler) ? newUpdateHandler : state.updateHandler,
            };
        }

        case rigthBarActions.CHANGE_CONFIRMATION_MODAL_STATE: {
            return {
                ...state,
                confirmationModal: payload,
            };
        }

        case rigthBarActions.CHANGE_MEDIA_INPUT: {
            const { sectionId, documentationCharacteristicId, activeStep, inputElementId, value } = payload;

            const updateHandler = handleAtomicMediaInputChange(
                state,
                activeStep,
                value,
                sectionId,
                documentationCharacteristicId,
                inputElementId
            );

            return {
                ...state,
                protocolTemplate: {
                    ...state.protocolTemplate,
                    sectionModels: state.protocolTemplate.sectionModels.map(section => {
                        if (section._id !== payload.sectionId) {
                            return section;
                        }

                        return {
                            ...section,
                            documentationCharacteristic: section.documentationCharacteristic.map(documentationCharacteristic => {
                                if (documentationCharacteristic._id !== payload.documentationCharacteristicId) {
                                    return documentationCharacteristic;
                                }

                                return {
                                    ...documentationCharacteristic,
                                    inputElement: documentationCharacteristic.inputElement.map(inputElement => {
                                        if (inputElement._id !== payload.inputElementId) {
                                            return inputElement;
                                        }

                                        return {
                                            ...inputElement,
                                            mediaInput: inputElement.mediaInput.map(mediaElement => {
                                                if (mediaElement.mediaName !== payload.value.mediaName) {
                                                    return mediaElement;
                                                }

                                                return {
                                                    ...mediaElement,
                                                    mediaLabel: payload.value.mediaLabel,
                                                    mediaDescription: payload.value.mediaDescription,
                                                    pinned: payload.value.pinned,
                                                    printInExport: payload.value.printInExport,
                                                };
                                            }),
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
                },
                updateHandler,
            };
        }

        default:
            return state;
    }
};

export const atomicInputElementPath =
    'protocolSections.$[protocolSectionsId].documentationCharacteristic.$[documentationCharacteristicId].inputElement.$[inputElementId]';
export const atomicProtocolSectionPath = 'protocolSections.$[protocolSectionsId]';

export const atomicInputMediaElementPath =
    'protocolSections.$[protocolSectionsId].documentationCharacteristic.$[documentationCharacteristicId].inputElement.$[inputElementId].mediaInput.$[mediaId]';

const handleAtomicMediaInputChange = (newState, activeStep, value, protocolSectionId, documentationCharacteristicId, inputElementId) => {
    const atomicIndex = newState.updateHandler.findIndex(atomic => {
        return (
            atomic.childrenIds.protocolSectionsId === protocolSectionId &&
            atomic.childrenIds.documentationCharacteristicId === documentationCharacteristicId &&
            atomic.childrenIds.inputElementId === inputElementId &&
            atomic.childrenIds.mediaId === value._id &&
            atomic.path === atomicInputMediaElementPath
        );
    });

    if (atomicIndex !== -1) {
        newState.updateHandler = update(newState.updateHandler, {
            [atomicIndex]: {
                values: {
                    media: {
                        mediaLabel: {
                            $set: value.mediaLabel,
                        },
                        mediaDescription: {
                            $set: value.mediaDescription,
                        },
                        pinned: {
                            $set: value.pinned,
                        },
                        printInExport: {
                            $set: value.printInExport,
                        },
                    },
                },
            },
        });
    } else {
        newState.updateHandler = update(newState.updateHandler, {
            $push: [
                {
                    inspectionReportId: activeStep,
                    operation: operationAtomicEnum.update,
                    path: atomicInputMediaElementPath,
                    values: {
                        media: {
                            mediaLabel: value.mediaLabel,
                            mediaDescription: value.mediaDescription,
                            pinned: value.pinned,
                            printInExport: value.printInExport,
                        },
                    },
                    childrenIds: {
                        protocolSectionsId: protocolSectionId,
                        documentationCharacteristicId: documentationCharacteristicId,
                        inputElementId: inputElementId,
                        mediaId: value._id,
                    },
                },
            ],
        });
    }

    return newState.updateHandler;
};

const handleAtomicSectionChange = (updateHandler, activeStep, sectionId, value, path) => {
    const atomicIndex = findAtomicProtocolSectionIndex(updateHandler, sectionId);

    if (atomicIndex !== -1) {
        return (updateHandler = update(updateHandler, {
            [atomicIndex]: {
                values: {
                    protocolSection: {
                        [path]: {
                            $set: value,
                        },
                    },
                },
            },
        }));
    } else {
        return update(updateHandler, {
            $push: [
                {
                    inspectionReportId: activeStep,
                    operation: operationAtomicEnum.update,
                    path: atomicProtocolSectionPath,
                    values: {
                        protocolSection: {
                            [path]: value,
                        },
                    },
                    childrenIds: {
                        protocolSectionsId: sectionId,
                    },
                },
            ],
        });
    }
};

const findAtomicProtocolSectionIndex = (updateHandler, protocolSectionId) => {
    return updateHandler.findIndex(atomic => {
        return atomic.childrenIds.protocolSectionsId === protocolSectionId && atomic.path === atomicProtocolSectionPath;
    });
};

const handleAtomicInputElementChange = (
    newState,
    activeStep,
    value,
    protocolSectionId,
    documentationCharacteristicId,
    inputElementId,
    path = 'value'
) => {
    const atomicIndex = findAtomicInputElementIndex(
        newState.updateHandler,
        protocolSectionId,
        documentationCharacteristicId,
        inputElementId
    );

    if (atomicIndex !== -1) {
        newState.updateHandler = update(newState.updateHandler, {
            [atomicIndex]: {
                values: {
                    inputElement: {
                        [path]: {
                            $set: value,
                        },
                    },
                },
            },
        });
    } else {
        newState.updateHandler = update(newState.updateHandler, {
            $push: [
                {
                    inspectionReportId: activeStep,
                    operation: operationAtomicEnum.update,
                    path: atomicInputElementPath,
                    values: {
                        inputElement: {
                            [path]: value,
                        },
                    },
                    childrenIds: {
                        protocolSectionsId: protocolSectionId,
                        documentationCharacteristicId: documentationCharacteristicId,
                        inputElementId: inputElementId,
                    },
                },
            ],
        });
    }

    return newState.updateHandler;
};

const handleAtomicRadioInputElementChange = (
    newState,
    activeStep,
    value,
    protocolSectionId,
    documentationCharacteristicId,
    inputElementId,
    inputElementsId,
    path = 'value'
) => {
    [...inputElementsId, inputElementId].forEach(elemId => {
        const atomicIndex = findAtomicInputElementIndex(newState.updateHandler, protocolSectionId, documentationCharacteristicId, elemId);

        if (atomicIndex !== -1) {
            newState.updateHandler = update(newState.updateHandler, {
                [atomicIndex]: {
                    values: {
                        inputElement: {
                            [path]: {
                                $set: elemId === inputElementId ? value : false,
                            },
                        },
                    },
                },
            });
        } else {
            newState.updateHandler = update(newState.updateHandler, {
                $push: [
                    {
                        inspectionReportId: activeStep,
                        operation: operationAtomicEnum.update,
                        path: atomicInputElementPath,
                        values: {
                            inputElement: {
                                [path]: elemId === inputElementId ? value : false,
                            },
                        },
                        childrenIds: {
                            protocolSectionsId: protocolSectionId,
                            documentationCharacteristicId: documentationCharacteristicId,
                            inputElementId: elemId,
                        },
                    },
                ],
            });
        }
    });

    return newState.updateHandler;
};

const handleAtomicFormattedInputElementChange = (
    newState,
    activeStep,
    value,
    formattedValue,
    protocolSectionId,
    documentationCharacteristicId,
    inputElementId
) => {
    const atomicIndex = findAtomicInputElementIndex(
        newState.updateHandler,
        protocolSectionId,
        documentationCharacteristicId,
        inputElementId
    );

    if (atomicIndex !== -1) {
        return newState.updateHandler.map((item, index) => {
            if (index !== atomicIndex) {
                return item;
            }

            return {
                ...item,
                values: {
                    inputElement: {
                        value,
                        formattedValue,
                    },
                },
            };
        });
    } else {
        return [
            ...newState.updateHandler,
            {
                inspectionReportId: activeStep,
                operation: operationAtomicEnum.update,
                path: atomicInputElementPath,
                values: {
                    inputElement: {
                        value,
                        formattedValue,
                    },
                },
                childrenIds: {
                    protocolSectionsId: protocolSectionId,
                    documentationCharacteristicId: documentationCharacteristicId,
                    inputElementId: inputElementId,
                },
            },
        ];
    }
};

const findAtomicInputElementIndex = (updateHandler, protocolSectionId, documentationCharacteristicId, inputElementId) => {
    return updateHandler.findIndex(atomic => {
        return (
            atomic.childrenIds.protocolSectionsId === protocolSectionId &&
            atomic.childrenIds.documentationCharacteristicId === documentationCharacteristicId &&
            atomic.childrenIds.inputElementId === inputElementId &&
            atomic.path === atomicInputElementPath
        );
    });
};
