import { useEffect, useState } from 'react';
import { Hub, I18n } from 'aws-amplify';
import Amplify, { Auth } from 'aws-amplify';
import {
    GATEWAY_URL,
    AWS_IDENTITY_POOL_ID,
    AWS_REGION,
    AWS_USER_POOL_ID,
    AWS_USER_POOL_WEB_CLIENT,
    AWS_AMPLIFY_DEFAULT_LANGUAGE,
    AWS_OAUTH_DOMAIN,
    USE_IDENTITY_PROVIDER,
} from './env';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER_BY_SUB } from '../gqlQueries/queries';
import client from './apolloClient';
import translationHungarian from '../resources/translations/aws-login-hu.json';

import { store } from '../redux/store';
import { setCurrentUserData } from '../components/pages/userSettings/store/userSettings.actions';

I18n.putVocabularies(translationHungarian);
I18n.setLanguage(AWS_AMPLIFY_DEFAULT_LANGUAGE);

const authTheme = {
    // ...AmplifyTheme,
    // sectionHeader: {
    //   ...AmplifyTheme.sectionHeader,
    //   backgroundColor: "black",
    //   color: "#009688"
    // },
    // button: {
    //   ...AmplifyTheme.button,
    //   backgroundColor: "black",
    //   color: "#009688"
    // }
};

const url = window.location.href.split('/');
const loginUrl = url[0] + '//' + url[2];
const amplifyConfig = {
    Auth: {
        region: AWS_REGION,
        userPoolId: AWS_USER_POOL_ID,
        userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT,
    },
    oauth: !USE_IDENTITY_PROVIDER ? undefined : {
        domain: AWS_OAUTH_DOMAIN,
        scope: ['openid', 'profile', 'email'],
        redirectSignIn: loginUrl,
        redirectSignOut: loginUrl,
        responseType: 'code',
    },
}

//Init Cognito
Amplify.configure(amplifyConfig);

// Method to check if User already exists in MongoDB
// if not sendIUserData method is invoked
const checkIfUserExits = async (sub, token) => {
    console.log('checking if user exists by sub?');
    try {
        const response = await fetch(GATEWAY_URL, {
            method: 'POST',
            body: JSON.stringify({
                query: `{
            getUsers(
              user: { awsPayload: { sub:"${sub}" } }
            ) {
              name
              email
              awsPayload {
                sub
              }
              _id
            }
          }
          `,
            }),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                authorization: `Bearer ${token}`,
            },
        });
        const json = await response.json();
        console.log('result from check if user exists: ', json.data?.getUsers);
        if (json.data?.getUsers.length > 0) {
            localStorage.setItem('currentUser', json.data?.getUsers[0]?._id); // TODO: use dispatch and set curentUser id
        }
        return json;
    } catch (error) {
        console.error('Error:', error);
    }
};

// send UserData from Cognito Auth to MongoDB
const sendUserData = async (name, firstName, email, sub, token) => {
    try {
        const response = await fetch(GATEWAY_URL, {
            method: 'POST',
            body: JSON.stringify({
                query: `
        mutation{
            createUser(user: {
              name: "${name}",
              firstName: "${firstName}",
              email: "${email}",
              awsPayload: {sub: "${sub}"}
            }) {
              code
              user {
                _id
              }
            }
          }
          `,
            }),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                authorization: `Bearer ${token}`,
            },
        });
        const json = await response.json();
        // debugger;
        if (json.errors) throw Error(json.errors[0].message);
        console.log('user has been created ', json);
        localStorage.setItem('currentUser', json.data?.createUser?.user?._id); // TODO: use dispatch and set curentUser id
        return json;
        // Todo: If still no success signUser Out
    } catch (error) {
        console.error('Error:', error);
    }
};
// Listening to events with Hub https://aws-amplify.github.io/docs/js/hub#listening-authentication-events
// when user signs in Check if user is already available in MongoDB, else create User in MongoDB
const listener = async (data) => {
    switch (data.payload.event) {
        case 'signIn':
        case 'cognitoHostedUI':
            const { given_name, family_name, email, sub } = data?.payload?.data?.signInUserSession?.idToken?.payload;
            const { jwtToken } = data.payload.data.signInUserSession.accessToken;
            let check = sub ? await checkIfUserExits(sub, jwtToken) : null;
            if (check?.errors) {
                console.log('User doesn´t exist -> sendingUserData');
                let createUser = await sendUserData(family_name, given_name, email, sub, jwtToken);
                return createUser;
            }
            // check if user._id has been written to localStorage successfully
            //verifyLocalStorage(sub, jwtToken);
            break;
        case 'signUp':
            console.info('user signed up', data.payload);
            break;
        default:
            break;
    }
};

const awsAuth = () => Hub.listen('auth', listener);

const getAwsToken = async () => {
    if (Auth) {
        const user = await Auth.currentAuthenticatedUser();
        let { jwtToken } = await user.signInUserSession.accessToken;
        return jwtToken;
    } else console.log('not auth');
};

const useJwtToken = () => {
    const [token, setJwtToken] = useState();
    useEffect(() => {
        (async function getToken() {
            const user = await Auth.currentAuthenticatedUser();
            const { jwtToken } = user.signInUserSession.accessToken;
            setJwtToken(jwtToken);
        })();
    }, []);
    return token;
};

const useSub = () => {
    const [sub, setSub] = useState();
    useEffect(() => {
        const getSub = async () => {
            const user = await Auth.currentAuthenticatedUser();
            const { sub } = user.signInUserSession.accessToken.payload;
            setSub(sub);
        };

        getSub();
    }, []);
    return sub;
};

const signOut = async () => {
    try {
        await client.clearStore();
        localStorage.clear();
        await Auth.signOut();
    }
    catch (err) {
        console.error(err);
    }
};

const useCurrentUser = async (sub, token) => {
    const [currentUser, setCurrentUser] = useState();
    useEffect(() => {
        const getUserData = async () => {
            const authUser = await Auth.currentAuthenticatedUser();
            const { sub } = authUser.signInUserSession.accessToken.payload;
            let { jwtToken } = authUser.signInUserSession.accessToken;
            const currentUser = await checkIfUserExits(sub, jwtToken);
            return currentUser;
        };
        setCurrentUser(getUserData());
    }, [sub, token]);
    return currentUser;
};

const useCurrentUserBySub = () => {
    const sub = useSub();

    const {
        data: currentUser,
        error,
        loading,
    } = useQuery(GET_CURRENT_USER_BY_SUB, {
        variables: { sub },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        skip: !sub,
        onCompleted: data => {
            store.dispatch(setCurrentUserData(data.getUsers[0]));
        },
    });

    return {
        data: currentUser && currentUser.getUsers ? currentUser.getUsers[0] : '',
        loading,
        error,
    };
};

const usePrivateRoleCondition = () => {
    const sub = useSub();
    const { data } = useCurrentUserBySub(sub);

    const executeCondition = allowedRoles => {
        if (!data) {
            return false;
        }

        return data.roles.some(userRole => allowedRoles.some(role => role === userRole));
    };

    return {
        executeCondition,
    };
};

export {
    awsAuth,
    authTheme,
    getAwsToken,
    signOut,
    useJwtToken,
    useSub,
    checkIfUserExits,
    useCurrentUser,
    useCurrentUserBySub,
    usePrivateRoleCondition,
};
