export const lightTheme = {
    typography: {
        fontFamily: 'Poppins, sans-serif',
    },
    palette: {
        type: 'light',
        primary: {
            main: '#16ABA1',
            light: '#1acbbf',
            dark: '#128780',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#FFFFFF',
            light: '#FFFFFF',
            dark: '#F2F2F2',
            contrastText: '#042727',
            button: '#DBE0EB',
        },
        background: {
            skeleton: '#dee4ed',
            paper: '#FFFFFF',
            paperDark: '#FFFFFF',
            paperSmoke: '#FBFCFD',
            contrastPaper: '#DBE0EB',
            expansionPanel: '#DBE0EB',
            active: '#f2f2f2',
            default: '#E8ECF2',
            alternative: '#ffffff',
            expand: '#DBE0EB',
            alternativeButton: '#F2F2F2',
            contrast: '#DBE0EB',
            contrastDark: '#ced5e4',
            activeItem: '#E8ECF2',
            shadow: 'rgba(0,0,0,0.25)',
            disabled: 'rgba(0, 0, 0, 0.12)',
            orange: '#EF7A12',
        },
        colors: {
            black: '#000',
            white: '#fff',
            alert: '#F5325C',
            lightAlert: '#FF6E50',
            blue: '#554DEF',
            purple: '#AA00FF',
            grey: '#797081',
            orange: '#FF7F00',
            green: '#16ABA1',
            lightGrey: '#B5BECC',
            darkGrey: '#8D90AF',
            yellow: '#EAB155',
            lightYellow: '#FFAF01',
            lemon: '#EFEF4D',
            lime: '#0EBF5A',
            lightGreen: '#089F14',
            extraLightGreen: '#9BF09B',
            carrot: '#F2994A',
            ligthCarrot: '#FFA04D',
            whiteVerse: '#16ABA1',
            lightCaramel: '#FFCFA4',
            backgroundGreen: '#DCEBEA',
            flipper: '#1979AF',
        },
        textColor: {
            primary: '#000000',
            secondary: '#8D90AF',
            placeholder: '#A2A2A2',
        },
        badge: {
            grey: '#B5BECC',
            color: '#FFFFFF',
        },
        input: {
            background: '#DBE0EB',
            borderColor: '#DBE0EB',
            labelColor: '#64676c',
            labelErrorColor: '#f44336',
            chipColor: '#f2f2f2',
        },
        tagInput: {
            background: '#f2f2f2',
            textColor: 'rgba(0, 0, 0, 0.87)',
            iconBackground: '#B5BECC',
        },
        switch: {
            primary: '#16ABA1',
            secondary: '#E8ECF2',
            textPrimary: '#FFFFFF',
            textSecondary: '#042727',
            textTertiary: '#8D90AF',
        },
        muiSwitch: {
            thumbColor: '#B5BECC',
            trackColor: '#E1E5EB',
            thumbActiveColor: '#16ABA1',
            trackActiveColor: '#16ABA13A',
        },
        checkbox: {
            default: '#ced5e4',
            contrast: '#ced5e4',
            light: '#b4b6cb',
            dark: '#b4b6cb',
        },
        table: {
            background: '#DBE0EB',
            border: '#CED4DB',
            paginationBorder: '#DFE3E8',
            paginationColor: '#212B36',
            paginationActiveColor: '#16ABA1',
            disabeled: '#919EAB',
            disabeledColor: '#C4CDD5',
        },
        kanban: {
            background: '#DBE0EB',
            activeBackground: '#ced5e4',
        },
        autocomplete: {
            labelColor: 'rgba(0, 0, 0, 0.54)',
        },
        icon: {
            default: '#000',
            color: '#B5BECC',
            activeColor: '#16ABA1',
            background: '#ced7e3',
            disabled: '#ababab',
        },
        timePicker: {
            backgroundImage:
                'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJXaW5kb3dUZXh0IiBkPSJNMTEuOTkgMkM2LjQ3IDIgMiA2LjQ4IDIgMTJzNC40NyAxMCA5Ljk5IDEwQzE3LjUyIDIyIDIyIDE3LjUyIDIyIDEyUzE3LjUyIDIgMTEuOTkgMnpNMTIgMjBjLTQuNDIgMC04LTMuNTgtOC04czMuNTgtOCA4LTggOCAzLjU4IDggOC0zLjU4IDgtOCA4eiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBmaWxsPSJXaW5kb3dUZXh0IiBkPSJNMTIuNSA3SDExdjZsNS4yNSAzLjE1Ljc1LTEuMjMtNC41LTIuNjd6Ii8+PC9zdmc+)',
        },
        dateTimePicker: {
            backgroundImage:
                'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJXaW5kb3dUZXh0IiBkPSJNMjAgM2gtMVYxaC0ydjJIN1YxSDV2Mkg0Yy0xLjEgMC0yIC45LTIgMnYxNmMwIDEuMS45IDIgMiAyaDE2YzEuMSAwIDItLjkgMi0yVjVjMC0xLjEtLjktMi0yLTJ6bTAgMThINFY4aDE2djEzeiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L3N2Zz4=)',
        },
        border: {
            color: '#8D90AF',
            lightColor: '#CED4DB',
        },
        warnMessage: {
            background: '#d5d5d5',
        },
        homeScreen: {
            color: '#042727',
            backgroundColor: '#E8ECF2',
            containerBoxShadow: '-6px -6px 10px #FFFFFF6D, 6px 6px 10px #0000001d',
            containerBoxShadowHover: '0px 0px 18px 6px rgba(255,255,255,0.3)',
            navbarBorderColor: '#DBE0EB',
            navbarBoxShadow: '0px 3px 34px #ced7e3',
            buttonIconWhite: '#16ABA1',
        },
        confirmationModal: {
            cancelButton: {
                background: '#FFFFFF',
                color: '#042727',
            },
            deleteButton: {
                background: '#F5325C',
                color: '#FFFFFF',
            },
        },
        mentionsInput: {
            textColor: '#000',
            suggestionsBackground: '#FFFFFF',
            suggestionSelectedItemBackground: '#C4EBE7',
        },
        scroll: {
            track: '#DBE0EB',
            thumb: '#C3CAD5',
        },
        template: {
            draggingBackground: '#e2e5e9',
            highlightColor: '#dee4ed',
            borderColor: '#E8ECF2',
            iconColor: '#AAB6C7',
            inputColor: '#FFFFFF',
            inputBackground: '#E8ECF2',
            inputBorderColor: '#B5BECC',
            headerColor: '#554DEF',
            headerBackground: '#DBE0EB',
            sectionBoxShadow: '0px 0px 7px #E8ECF2',
            containerBoxShadow: '0px 0px 6px #ADADAD6A',
            containerBorderColor: '#8D90AF5A',
            containerHoverColor: '#f5f5f5',
            containerHoverLightColor: '#FBFCFE',
            supportButtonColor: '#000',
            supportButtonBackground: '#E8ECF2',
            contrastButtonColor: '#8D90AF',
            contrastButtonBackground: '#DBE0EB',
            circleButtonShadow: '0px 0px 4px 3px rgba(0, 0, 0, 0.13)',
            configurationButtonShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.16)',
        },
        link: {
            color: 'rgba(0,0,0,.87)',
        },
    },
};
