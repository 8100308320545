import { combineReducers } from 'redux';

import { maintenanceStandardAssetsReducer } from './assets/Assets.reducers';
import { maintenanceStandardReportsReducer } from './reports/store/maintenance-standard-reports.reducer';
import { maintenanceStandardSchedulerReducer } from './scheduler/ShedulerReducer';
import { maintenanceStandardTicketsReducer } from './tickets/reducer';
import { ticketsReducer } from './pages/ticketsPage/store/tickets.reducer';
import { ticketsSearchReducer } from './pages/ticketsSearchPage/store/ticketsSearch.reducer';
import { ticketsFiltersReducer } from './common/components/topBar/store/ticketsFilters.reducer';

export const maintenanceStandardReducer = combineReducers({
    tickets: maintenanceStandardTicketsReducer,
    reports: maintenanceStandardReportsReducer,
    assets: maintenanceStandardAssetsReducer,
    scheduler: maintenanceStandardSchedulerReducer,
    ticketsPage: ticketsReducer,
    ticketsSearchPage: ticketsSearchReducer,
    ticketsFilters: ticketsFiltersReducer,
});
